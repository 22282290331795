import React, {Component} from 'react';
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

class OutrasNoticias extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props.props);
        return (
            <div className="container mb-3 mt-3">
                <hr/>
                <h3>Confira outras notícias da Cia. Ibérica:</h3>
                <div className="row">
                    {
                        this.props.props.edges.slice().map(({node, index}) => {
                            if (node.imagemDestacada !== null) {
                                return(
                                    <div className="col-sm-4 iberica-grid-produto-col mt-3 mb-3" key={node.urlNoticia}>
                                        <div className="card iberica-grid-produto-card">
                                            <GatsbyImage className="image card-img-top iberica-grid-produto-photo" image={node.imagemDestacada.gatsbyImageData} alt={node.titulo}/>
                                            <div className="card-body">
                                                <Link to={"/noticias/" + node.urlNoticia + "/"} style={{textDecoration: "none", color: "black"}}><h5 className="card-title">{node.titulo}</h5></Link>
                                                <Link to={"/noticias/" + node.urlNoticia + "/"} className="btn btn-primary">Ver Notícia</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>


            </div>
        )
    }
}

export default OutrasNoticias;