import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {graphql} from "gatsby";
import {renderRichText} from 'gatsby-source-contentful/rich-text'
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {INLINES, BLOCKS, MARKS} from '@contentful/rich-text-types'
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import HeroImageNoticias from "../components/Noticias/HeroImage";
import {Helmet} from "react-helmet";
import OutrasNoticias from "../components/Noticias/OutrasNoticias";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

class NoticiaPage extends Component {
    render() {
        const options = {
            renderMark: {
                [MARKS.BOLD]: (text) => <b>{text}</b>
            },
            renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br/>, text]),
            renderNode: {
                [INLINES.HYPERLINK]: (node, children) => {
                    const {uri} = node.data
                    return (
                        <a href={uri} style={{color: ""}} target="_blank" rel="noopener, noreferrer">
                            {children}
                        </a>
                    )
                },
                [BLOCKS.HEADING_2]: (node, children) => {
                    return <h2>{children}</h2>
                },
                [BLOCKS.EMBEDDED_ASSET]: (node) => {
                    const {gatsbyImageData, description} = node.data.target
                    return (
                        <GatsbyImage
                            image={getImage(gatsbyImageData)}
                            alt={description}
                        />
                    )
                },
            }
        }
        return (
            <>
                <Helmet>
                    <html lang="pt-BR" amp/>
                    <meta charSet="UTF-8"/>
                    <title>{this.props.data.article.titulo} | Notícias Cia. Ibérica</title>
                    <meta name="description" content={this.props.data.article.titulo}/>
                    <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large"/>
                    <link rel="canonical"
                          href={`https://www.ciaiberica.com.br/noticias/${this.props.data.urlNoticia}/`}/>
                    <meta property="og:locale" content="pt-BR"/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content={this.props.data.article.titulo + "| Notícias Cia. Ibérica"}/>
                    <meta property="og:description" content={this.props.data.article.titulo}/>
                    <meta property="og:url"
                          content={`https://www.ciaiberica.com.br/noticias/${this.props.data.urlNoticia}/`}/>
                    <meta property="og:sitename" content="Cia. Ibérica"/>
                    <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda"/>
                    <meta property="article:published_time" content={this.props.data.articleData.createdAt}/>
                    <meta property="article:modified_time" content={this.props.data.articleData.updatedAt}/>
                    {this.props.data.article.imagemDestacada !== null ?
                        <meta property="og:image" content={this.props.data.article.imagemDestacada.file.url}/> : <></>}
                </Helmet>
                <Header/>
                <HeroImageNoticias props={this.props.data}/>

                <div className="container">
                    <h1 className="mt-3 mb-3">{this.props.data.article.titulo}</h1>
                    <p style={{color: "#a2a6ab"}}>Publicado em {this.props.data.article.createdAt} ・ Por Time
                        Ibérica</p>
                    {
                        renderRichText(this.props.data.article.textoNoticia, options)
                    }
                </div>
                <OutrasNoticias props={this.props.data.otherNews}/>
                <Footer/>
            </>
        )
    }

}

export const query = graphql`
query($urlNoticia:String!) {
    article: contentfulNoticias
(
    urlNoticia: {eq:$urlNoticia}
) {
    id
    titulo
    urlNoticia

    createdAt(formatString: "DD/MM/yyyy, HH:mm")

    imagemDestacada {
    gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])

    file {
    url
}
}
textoNoticia
{
    raw
    references
    {
    ...
        on
        ContentfulAsset
        {
            contentful_id
            title
            description
            gatsbyImageData(quality
        :
            100, placeholder
        :
            BLURRED, formats
        :
            [AUTO, WEBP, AVIF]
        )
            __typename
        }
    }
}
}
articleData: contentfulNoticias(urlNoticia
:
{
    eq:$urlNoticia
}
)
{
    createdAt
    updatedAt
}
poster: contentfulNoticias(urlNoticia
:
{
    eq:$urlNoticia
}
)
{
    titulo
    imagemDestacada
    {
        gatsbyImageData(quality
    :
        100, placeholder
    :
        BLURRED, formats
    :
        [AUTO, WEBP, AVIF]
    )
    }
}
otherNews: allContentfulNoticias(filter
:
{
    urlNoticia: {
        ne: $urlNoticia
    }
}
,
limit: 3
)
{
    edges
    {
        node
        {
            titulo
            imagemDestacada
            {
                gatsbyImageData(quality
            :
                100, placeholder
            :
                BLURRED, formats
            :
                [AUTO, WEBP, AVIF]
            )
            }
            urlNoticia
        }
    }
    distinct(field
:
    titulo
)
}
}
`

export default NoticiaPage;