import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

function HeroImageNoticias(props) {
        return(
            <>
                {
                    props.props.poster.imagemDestacada !== null ? <GatsbyImage className="image card-img-top" style={{maxHeight: "600"}}
                            objectFit={"scale-down"}
                            image={props.props.poster.imagemDestacada.gatsbyImageData}
                            alt={props.props.poster.titulo}
                        /> : <></>
                    }
                }
            </>
        )
} 

export default HeroImageNoticias